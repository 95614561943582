<template>
    <component :is="menuComponent" class="c-base-menu-modal" v-bind="menuProps" />
</template>

<script>
import { mapMutations } from 'vuex';

import { baseMenu } from '~coreModules/core/js/provider-symbol-constants';
import { MODALS_MODULE_NAME, POP_MODAL } from '~coreModules/modals/js/modals-store';

export default {
    name: 'BaseMenuModal',
    provide() {
        return {
            [baseMenu]: this.closePopover,
        };
    },
    props: {
        menuComponent: {
            type: [Function, Object],
            required: true,
        },
        menuProps: {
            type: Object,
            default: () => ({}),
        },
    },
    watch: {
        '$mediaQueries.isExtraSmall': function closeModalOnSizeChange(isExtraSmall, wasExtraSmall) {
            if (wasExtraSmall && !isExtraSmall) {
                this.popModal();
            }
        },
    },
    methods: {
        ...mapMutations(MODALS_MODULE_NAME, {
            popModal: POP_MODAL,
        }),
        closePopover(isLaunchingModal) {
            if (!isLaunchingModal) {
                this.popModal();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.c-base-menu-modal {
    background-color: $nu-white;
}
</style>
